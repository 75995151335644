import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode } from 'primereact/api';
import { createDatabase, storeIntoDB, retrieveFromDB } from './helpers/database';
import * as Yup from 'yup';

import { formatDate, dateToStr, uniqueColumnValues } from './helpers/common';
import { InputWrapper, AutoCompleteFilter } from './helpers/inputs';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';

const db = 'job-tracker';

/**
 * Show edit, pause, and delete buttons
 *
 * @component
 * @param {array}            data
 * @param {function(array)}  setData
 * @param {object}           rowData
 * @param {function(object)} setRow
 * @param {function(bool)}   setVisible
 * @returns {ReactElement}
 */
const ActionButtons = ({ data, setData, rowData, setRow, setVisible }) => (
	<div className="action-btn">
		<Button
			icon="pi pi-pencil"
			rounded text
			aria-label="Edit Row"
			onClick={() => {
				setRow({
					...rowData,
					startTime: new Date(rowData.date),
				});
				setVisible(true);
			}}
		/>
		<Button
			icon="pi pi-times"
			rounded text
			aria-label="Delete Row"
			severity="danger"
			onClick={e => {
				confirmDialog({
					trigger: e.currentTarget,
					message: 'Are you sure you want to proceed deletion?',
					header: 'Confirmation',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						const _data = data.filter(x => x.id !== rowData.id);
						setData(_data);
						storeIntoDB(db, _data);
					},
				});
			}}
		/>
	</div>
);

/**
 * A time tracking application
 *
 * @component
 * @returns {ReactElement}
 */
const JobTracker = () => {

	const [row, setRow] = useState({});
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [filters, setFilters] = useState({
		date: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		title: { value: null, matchMode: FilterMatchMode.CONTAINS },
		company: { value: null, matchMode: FilterMatchMode.CONTAINS },
		source: { value: null, matchMode: FilterMatchMode.CONTAINS },
		recruiter: { value: null, matchMode: FilterMatchMode.CONTAINS },
		status: { value: null, matchMode: FilterMatchMode.CONTAINS },
		resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
		site: { value: null, matchMode: FilterMatchMode.CONTAINS },
		notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [loading, setLoading] = useState(true);
	const dt = useRef(null);
	const uniques = uniqueColumnValues(Object.keys(filters), data);

	// Retrieve entries from indexedDB
	useEffect(() => {
		async function fetchData() {
			await createDatabase(db);
			const _data = await retrieveFromDB(db);
			setData(_data);
			setLoading(false);
		}
		fetchData();
	}, []);

	/**
	 * Display edit, stop, and delete button for each row in the grid
	 * @param {*} rowData
	 * @returns
	 */
	const actionBtn = (rowData) => (
		<ActionButtons
			data={data}
			setData={setData}
			rowData={rowData}
			setRow={setRow}
			setVisible={setVisible}
		/>
	);

	/**
	 * Export grid data to the CSV file
	 */
	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	};

	/**
	 * A dropdown list of dates as a filter
	 *
	 * @param {object} options
	 * @returns {ReactElement}
	 */
	const dateFilterTemplate = (options) => (
		<Dropdown
			value={options.value}
			options={uniques.date}
			onChange={(e) => options.filterApplyCallback(e.value)}
			placeholder="Filter Date"
			className="p-column-filter"
			showClear
		/>
	);

	/**
	 * A dropdown list of activities as a filter
	 *
	 * @param {object} options
	 * @returns {ReactElement}
	 */
	const filterTemplate = (options, column) => (
		<Dropdown
			value={options.value}
			options={uniques[column]}
			onChange={(e) => options.filterApplyCallback(e.value)}
			placeholder={"Filter "+column}
			className="p-column-filter"
			showClear
		/>
	);

	/**
	 * Display action buttons above the grid
	 *
	 * @returns {ReactElement}
	 */
	const header = () => (
		<div id="toolbar" className="flex justify-content-end">
			<div className="toolbar-left">
				<Button
					label="Add Job"
					onClick={(e) => {
						setVisible(true);
						setRow({
							startTime: new Date(),
							endTime: '',
							activity: '',
							notes: '',
						});
					}}
				/>

			</div>
			<div className="toolbar-right">
				<Button
					type="button"
					label="Export"
					onClick={() => exportCSV(false)}
					data-pr-tooltip="CSV"
				/>
			</div>
		</div>
	);

	return (
		<div id="time-clock">
			<ConfirmDialog />
			<DataTable
				ref={dt}
				value={data}
				paginator rows={25} rowsPerPageOptions={[25, 100, 500]}
				dataKey="id"
				stripedRows
				header={header}
				filters={filters} filterDisplay="row"
			>
				<Column
					body={actionBtn}
					className="action-column"
				/>
				<Column
					field="date"
					header="Application Date"
					body={rowData => formatDate(rowData.date)}
					className="time-column"
					filter filterField="date" showFilterMenu={false} filterPlaceholder="Filter " filterElement={dateFilterTemplate}
					sortable
				/>
				<Column
					field="title"
					header="Title"
					filter filterField="title" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'title')}
					sortable
				/>
				<Column
					field="company"
					header="Company"
					filter filterField="company" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'company')}
					sortable
				/>
				<Column
					field="source"
					header="Source"
					filter filterField="source" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'source')}
					sortable
				/>
				<Column
					field="recruiter"
					header="Recruiter"
					filter filterField="recruiter" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'recruiter')}
					sortable
				/>
				<Column
					field="status"
					header="Status"
					filter filterField="status" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'status')}
					sortable
				/>
				<Column
					field="resume"
					header="Resume"
					filter filterField="resume" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'resume')}
					sortable
				/>
				<Column
					field="site"
					header="Site"
					filter filterField="site" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'site')}
					sortable
				/>
				<Column
					field="notes"
					header="Notes"
					className="notes"
					filter filterField="notes" showFilterMenu={false} filterPlaceholder="Filter notes"
					sortable
				/>
			</DataTable>
			<Dialog header="Add Event" visible={visible} style={{ width: '35rem' }} onHide={() => setVisible(false)}>
				<Formik
					initialValues={row}
					validationSchema={Yup.object().shape({
						startTime: Yup.string().required('Required')
					})}
					onSubmit={(values, { setSubmitting }) => {

						values.date = dateToStr(values.startTime);

						let _data = [];

						// If an id exists, then update data rather than adding data
						if (values.id !== undefined) {
							_data = data.map((x, i) => (x.id === values.id) ? values : x);

							// Create an id
						} else {
							if (data.length === 0) {
								values.id = 0;
							} else {
								values.id = Math.max(...data.map(x => x.id))+1;
							}

							_data = data.concat(values);
						}

						setData(_data);
						storeIntoDB(db, _data);
						setVisible(false);
					}}
					>
					{props => (
						<form onSubmit={props.handleSubmit}>
							<InputWrapper name="startTime" formikProps={props} title="Application Date">
								<Calendar
									name="startTime"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.startTime}
									showButtonBar showTime hourFormat="12"
								/>
							</InputWrapper>
							<InputWrapper name="title" formikProps={props} title="Title">
								<AutoCompleteFilter
									name="title"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="company" formikProps={props} title="Company">
								<AutoCompleteFilter
									name="company"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="source" formikProps={props} title="Source">
								<AutoCompleteFilter
									name="source"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="recruiter" formikProps={props} title="Recruiter">
								<AutoCompleteFilter
									name="recruiter"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="status" formikProps={props} title="Status">
								<AutoCompleteFilter
									name="status"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="resume" formikProps={props} title="Resume">
								<AutoCompleteFilter
									name="resume"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="site" formikProps={props} title="Application Site">
								<AutoCompleteFilter
									name="site"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="notes" formikProps={props} title="Notes">
								<InputTextarea
									name="notes"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.notes}
									rows={5} cols={30}
								/>
							</InputWrapper>
							<Button label="Update" type="submit" disabled={props.isSubmitting} />
						</form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
}

export default JobTracker;
