import './App.css';
import React from 'react';
import JobTracker from './JobTracker';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';

const App = () => (
	<JobTracker />
)

export default App;
